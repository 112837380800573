













































import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import { Guid } from "guid-typescript";
import {coefficientOptionOperationFamilies} from "@/models/CoefficientConfig";

@Component({
  components: {
    Modal: () => import("@/components/Shared/Modal.vue"),
  },
})
export default class OperationFamiliesModal extends Vue {
  /**
   * Defines if the modal is opened or not
   */
  @Prop({ default: false }) public show!: boolean;
  /**
   * Property set when the modal is opened
   */
  @Prop({ default: null }) public props!: any;

  private isSelectAllClicked: boolean = false;
  private selectedOperationFamilies: Array<any> = [];
  private get operationFamiliesOptions(): Array<any> {
    return this.props?.rowField.operationFamilies;
  }
  private get operationFamilies() : coefficientOptionOperationFamilies[] {
    return this.props?.rowData.operationFamilies;
  }
  private set operationFamilies(operationFamilies: coefficientOptionOperationFamilies[]) {
    this.props.rowData.operationFamilies = operationFamilies;
  }
  /**
   * #######
   * Methods
   * #######
   */

  private cancel() {
    this.$emit("close");
  }

  private validate() {
    const operationFamilies = this.selectedOperationFamilies.map(
        (opFamily) => ({
          key: opFamily.id,
          value: opFamily.name,
          // pour garder l'id de l'OperationFamilyCoefficientOption pour la gestion du coefficient nature des taches
          id: this.operationFamilies?.find(of => of.key === opFamily.id)?.id ?? Guid.createEmpty().toString()
        })
    );
    const discardedOperationFamilies:coefficientOptionOperationFamilies[] = this.operationFamilies.filter(of => operationFamilies.findIndex(s => of.key === s.key) === -1 );
    this.operationFamilies = operationFamilies;
    this.$emit("discardedOperationFamilies" ,{discardedOperationFamilies,rowData:this.props.rowData});
    this.$emit("validate", this.props.rowData);
  }
  private selectAll() {
    if (this.isSelectAllClicked) {
      this.selectedOperationFamilies = [];
    } else {
      this.selectedOperationFamilies = [...this.operationFamiliesOptions];
    }
    this.isSelectAllClicked = !this.isSelectAllClicked;
  }

  /**
   * ######
   * Hooks
   * ######
   */
  public async created() {}

  /**
   * ######
   * Watchers
   * ######
   */
  @Watch("show")
  async onShowChange(value: boolean, oldValue: boolean) {
    if (value == false) {
      this.selectedOperationFamilies = [];
    } else {
      const selectedOperationFamilies = this.operationFamilies.map((opFamily) =>
        this.operationFamiliesOptions.find(
          (option) => option.id === opFamily.key
        )
      );
      this.isSelectAllClicked = (this.operationFamiliesOptions.length === selectedOperationFamilies.length);
      this.selectedOperationFamilies = selectedOperationFamilies;
    }
  }
}
